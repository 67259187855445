// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("../src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-auto-login-page-js": () => import("../src/Templates/AutoLoginPage.js" /* webpackChunkName: "component---src-templates-auto-login-page-js" */),
  "component---src-templates-redirect-page-js": () => import("../src/Templates/RedirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-videos-page-js": () => import("../src/Templates/VideosPage.js" /* webpackChunkName: "component---src-templates-videos-page-js" */),
  "component---src-templates-profile-page-js": () => import("../src/Templates/ProfilePage.js" /* webpackChunkName: "component---src-templates-profile-page-js" */),
  "component---src-templates-create-record-page-js": () => import("../src/Templates/CreateRecordPage.js" /* webpackChunkName: "component---src-templates-create-record-page-js" */),
  "component---src-templates-edit-record-page-js": () => import("../src/Templates/EditRecordPage.js" /* webpackChunkName: "component---src-templates-edit-record-page-js" */),
  "component---src-templates-record-detail-page-js": () => import("../src/Templates/RecordDetailPage.js" /* webpackChunkName: "component---src-templates-record-detail-page-js" */),
  "component---src-templates-archive-page-js": () => import("../src/Templates/ArchivePage.js" /* webpackChunkName: "component---src-templates-archive-page-js" */),
  "component---src-templates-faq-page-js": () => import("../src/Templates/FaqPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

